import React from "react";
import { BrowserRouter, Route, Routes,Navigate  } from "react-router-dom";
import Login from "./pages/Login";
import AdminForm from "./pages/AdminForm";
import Form from "./pages/Form";
import PrivateRoute from "./utils/PrivateRoute";
import Upload from "./pages/Upload";
import Thankyou from "./pages/Thankyou";
import NotFound from "./pages/NotFound";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
     <Routes>
    <Route path="/" element={<Navigate to="/login" />} />
    <Route path="/login/:mobile" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route
      path="/form"
      element={
        <PrivateRoute>
          <Form />
        </PrivateRoute>
      }
    />
    <Route
      path="/upload"
      element={
        <PrivateRoute>
          <Upload />
        </PrivateRoute>
      }
    />
    <Route
      path="/admin-form"
      element={
        <PrivateRoute>
          <AdminForm />
        </PrivateRoute>
      }
    />
    <Route
      path="/thank-you"
      element={
        <PrivateRoute>
          <Thankyou />
        </PrivateRoute>
      }
    />
    {/* <Route
      path="*"
      element={
        <PrivateRoute>
          <NotFound />
        </PrivateRoute>
      }
    /> */}
  </Routes>
    </BrowserRouter>
  );
};

export default Router;
