/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import InputText from "../../components/atoms/InputText";
import Button from "../../components/atoms/Button";
import { useTimer } from "react-timer-hook";

import { useSessionStorage } from "../../hooks/useSessionStorage";

function Login() {
  const navigate = useNavigate();
  const urlMob = location?.pathname;

  const [mobileNumber, setMobileNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");

  const [authToken, setAuthToken] = useSessionStorage("feemonk_data", "");

  const { seconds, isRunning, restart } = useTimer({
    expiryTimestamp: new Date(new Date().getTime() + 30 * 1000),
    autoStart: false,
  });

  useEffect(() => {
    if (urlMob.split("/")[2]) {
      if (urlMob.split("/")[2].length > 10) {
        return;
      } else {
        setMobileNumber(urlMob.split("/")[2]);
      }
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("feemonk_data")) {
      localStorage.removeItem("feemonk_data");
    }
  }, []);

  const saveDataToLS = (data: any, auth_token: any) => {
    sessionStorage.setItem(
      "feemonk_data",
      JSON.stringify({
        user_status: data?.status,
        mob: data?.mobile,
        value: auth_token,
      })
    );
  };

  const sendOtp = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobile: mobileNumber,
    });

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/login/send-otp`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.error) {
          alert(result.message[0]);
        } else if (result.message === "Successful") {
          setOtpSent(true);
        }
        // console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const verifyOtp = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobile: mobileNumber,
      otp: otp,
    });

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/login/verify-otp`, requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        // console.log(result);
        // console.log(result.message);

        if (result.message === "Invalid OTP") {
          alert("Invalid OTP");
        } else if (result.message === "Successful") {
          sessionStorage.setItem("token", result?.data);
          authenticate(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const authenticate = (auth_token: string) => {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${auth_token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/summary`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        console.log(result?.data?.status);

        // setAuthToken({
        //   ...authToken,
        //   user_status: result?.data?.status,
        // })
        saveDataToLS(result?.data, auth_token);

        if (result?.data?.status === "Admin") {
          return navigate(`/upload`);
        }

        if (
          result?.data?.status === "New" ||
          result?.data?.status === "Edited"
        ) {
          return navigate(`/admin-form`);
        }
        if (
          result?.data?.status === "Received" ||
          result?.data?.status === "CoapplicantSubmitted"
        ) {
          return navigate(`/thank-you`);
        }

        if (result?.data?.status === "Coapplicant") {
          return navigate(`/admin-form?coapp=true`);
        }

        // if (
        //   result?.data?.status === "Admin" ||
        //   result?.data?.status === "Edited"
        // ) {
        //   navigate(`/form/${mobileNumber}`);
        // } else {
        //   navigate("/form");
        // }
      })
      .catch((error) => console.log("error", error));
  };

  const mobileOnChange = (e: any) => {
    if (e.target.value.length > 10) {
      return;
    } else {
      setMobileNumber(e.target.value);
    }
  };

  return (
    <div className={styles.main}>
      <div
        className={styles.container}
        style={{
          backgroundColor: "#F77723",
        }}
      ></div>
      <div className={styles.container}>
        <img
          src={"/feemonklogo.png"}
          style={{ width: "10rem" }}
          alt="feemonk_logo"
        />
        <div style={{ padding: "1rem" }}>
          <p style={{ fontWeight: "500" }}>
            Enter 10 digit Mobile Number to Sign in
          </p>
          <InputText
            id="mobileNumber"
            placeholder="Mobile Number"
            style={{
              width: "15rem",
            }}
            defaultValue={urlMob}
            type="number"
            changeHandler={mobileOnChange}
            value={mobileNumber}
          />
          {mobileNumber.length === 10 && (
            <div
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Button
                text={
                  otpSent ? (seconds > 0 ? seconds : "Resend OTP") : "Send OTP"
                }
                disabled={otpSent ? (seconds > 0 ? true : false) : false}
                onPress={() => {
                  sendOtp();
                  restart(new Date(new Date().getTime() + 30 * 1000));
                }}
              />
            </div>
          )}

          {otpSent && (
            <div
              style={{
                marginTop: "3rem",
                marginBottom: "1rem",
              }}
            >
              <InputText
                placeholder="Enter OTP"
                style={{
                  width: "15rem",
                }}
                type="number"
                changeHandler={(e: any) => setOtp(e.target.value)}
                value={otp}
              />
              <div
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <Button
                  text={"Sign In"}
                  disabled={otp.length < 4}
                  onPress={() => {
                    verifyOtp();
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
