import React, { useEffect, useState } from "react";
import Label from "../../components/atoms/Label";
import InputText from "../../components/atoms/InputText";
import styles from "./styles.module.css"; 
import Button from "../../components/atoms/Button";
import Checkbox from "../../components/atoms/Checkbox";
import { useSessionStorage } from "../../hooks/useSessionStorage";

import Navbar from "../../components/molecules/Navbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import ErrorDialog from "../../components/molecules/ErrorDialog";
import close from '../../images/icons/close.png'
import axios from 'axios';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { API_URL_STAGING } from "../../utils";
import { Http2ServerResponse } from "http2";

interface FormValue {
  inst: string;
  course: string;
  coursefee: string;
  fname: string;
  lname: string;
  // midname:string;
  sname:string;
  dob: string;
  phone: string;
  coname: string;
  gender: string;
  Email1: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  emp: string;
  sal: string;
  typofB: string;
  Income: string;
  adhaarnum: string;
  adharf: string;
  adharb: string;
  pan: string;
  empType:string;
  pannum: string;
  latitude:number;
  longitude:number;
  tenure:string;
  salesperson:string;
  check: boolean;
  isSubmit: string;
  errorMsg: string;
}
const states=[
  "Select State",
  "JAMMU & KASHMIR",
  "HIMACHAL PRADESH",
  "PUNJAB",
  "CHANDIGARH",
  "UTTARANCHAL",
  "HARYANA",
  "DELHI",
  "RAJASTHAN",
  "UTTAR PRADESH",
  "BIHAR",
  "SIKKIM",
  "ARUNACHAL PRADESH",
  "NAGALAND",
  "MANIPUR",
  "MIZORAM",
  "TRIPURA",
  "MEGHALAYA",
  "ASSAM",
  "WEST BENGAL",
  "JHARKHAND",
  "ORISSA",
  "CHATTISGARH",
  "MADHYA PRADESH",
  "GUJARAT",
  "DAMAN & DIU",
  "DADRA & NAGAR HAVELI",
  "MAHARASHTRA",
  "ANDHRA PRADESH",
  "KARNATAKA",
  "GOA",
  "LAKSHADWEEP",
  "KERALA",
  "TAMIL NADU",
  "PONDICHERRY",
  "ANDAMAN AND NICOBAR ISLANDS",
  "TELANGANA",
  "LADAKH",
  "APO Address",
]

function AdminForm() {
  const [ocrData, setOcrData] = useSessionStorage("ocr_data", "");
  const [error, setError] = useState("");
  const [phoneCheck,setPhoneCheck]=useState("")
  const [location, setLocation] = useState({
    latitude:0,
    longitude:0});
  const { search } = useLocation();
  

  const navigate = useNavigate();

  const [radio, setRadio] = useState("EMPLOYED");
  const [coapp, setCoapp] = useState("APP");
  const [instituteList,setInstituteList]=useState([])  
  const [consentId,setConsentId]=useState('')  
  const [startingPhrase,setStarterPhrase]=useState("Type Institute Name")

  const [value, setValue] = useState<FormValue>({
    inst: "",
    course: "",
    coursefee: "",
    fname: "",
    lname: "",
    // midname:"",
    sname:"",
    dob: "",
    phone: "",
    coname: "",
    gender: "",
    Email1: "",
    Address: "",
    City: "",
    State: "",
    Zip: "",
    emp: "",
    sal: "",
    typofB: "",
    empType:"",
    Income: "",
    adhaarnum: "",
    adharf: "",
    adharb: "",
    pan: "",
    pannum: "",
    latitude:0,
    longitude:0,
    tenure:"",
    salesperson:"",
    check: false,
    isSubmit: "",
    errorMsg: "",
  });

  const [authToken, setAuthToken] = useSessionStorage("feemonk_data", "");
  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success,err);
    } else {
      console.log("Geolocation not supported");
    }
  }

  function success(position:any) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    
    setLocation({
        latitude,
        longitude
    });
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

    
  }

  function err() {
    console.log("Unable to retrieve your location");
  }

    
const getInstituteInfo=(text:any)=>{
  const instituteInfoUrl=`${process.env.REACT_APP_BASE_URL}/institute/info/name?name=${text}`;
  value.inst=text
  if(text.length>=4)
  {
    axios.get(instituteInfoUrl,{
      headers:{
        Authorization:`Bearer ${authToken}`
      }
    }).then((res)=>{
      if(res.data.data.length===0)
      {
        setInstituteList([])
        setStarterPhrase("No institute Found")
      }
      else
      setInstituteList(res.data.data)

    })
  }
  else{
    setInstituteList([])
    setStarterPhrase("Type Institute Name")
  }
}
const [selectedInstitute,setSelectedstyleInstituteName]=useState('')
const handleSearchInstituteDetails=(data :any)=>{
  
  // setCurrentInstituteId(data.id)
  (document.getElementById('styleinstituteNameField') as any).value=data
  value.inst=data
   setInstituteList([]);
   setSelectedstyleInstituteName(data)
   
  
}



  const handleChange = (key: string, val: string) => {
    setValue({ ...value, [key]: val });
  };

  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [isEmailError, showNoValidEmail] = useState(false);

  const toggle = () => ( setModal(!modal));
  const errorToggle = () => ( setErrorModal(!errorModal));
  const handleSubmit =
    (value: FormValue) => {
      // if (!value.check) {
      //   setError("Please accept the terms and conditions");
      //   return;
      // }
      const validateEmail = (email:string) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };
  
      
      if(coapp!=="CO_APP")
      {
            if (!value.inst) {
        setError("Please enter Institute name");
        errorToggle()

        return;

      }

        if (!value.course) {
        setError("Please enter the course name");
        errorToggle()

        return;
      }

      if (!value.coursefee) {
        setError("Please enter the course fee");
        errorToggle()

        return;
      }
      }
      if (!value.fname) {
        setError("Please enter first name");
        errorToggle()
        return;
      }
     if(authToken.user_status!=="Coapplicant")
     { 
      if (!value.sname) {
        setError("Please enter student name");
        errorToggle()

        return;
      }
    }
    
  
      if (!value.phone || value.phone.length!==10) {
        setError("Please ensure 10-digit number and should match with given phone number");
        errorToggle()

        return;
      }
      if (!value.dob || value.dob === "Invalid date") {
        setError("Please enter date of birth");
        errorToggle()

        return;
      }
      if (!value.gender) {
        setError("Please enter gender");
        errorToggle()

        return;
      }
      if (!(validateEmail(value.Email1))) {
      
        setError("Please enter valid email");
        errorToggle()

        return;
      }
      // if (!(value.Email1)) {
      
      //   setError("Please enter valid email");
      //   errorToggle()

      //   return;
      // }


      if (!value.tenure || (parseInt(value.tenure)>=37)) {
        setError("Please enter tenure , ensure tenure is less than 36 (months)");
        errorToggle()
        return;
      }
      if (!value.Address) {
        setError("Please enter address");
        errorToggle()

        return;
      }
      if (!value.City) {
        setError("Please enter city");
        errorToggle()

        return;
      }
      
      if (!value.State) {
        setError("Please enter state");
        errorToggle()

        return;
      }
      if (!value.Zip) {
        setError("Please enter zip code");
        errorToggle()

        return;
      }

      // if (radio === "EMPLOYED") {
      //   if (!value.emp) {
      //     setError("Please enter employer name");
      //   errorToggle()

      //     return;
      //   }
      //   if (!value.sal) {
      //     setError("Please enter salary");
      //   errorToggle()

      //     return;
      //   }
      // }

      // if (radio === "SELF_EMPLOYED") {
      //   if (!value.Income) {
      //     setError("Please enter income");
      //   errorToggle()

      //     return;
      //   }
      //   if (!value.typofB) {
      //     setError("Please enter type of business");
      //   errorToggle()

      //     return;
      //   }
      //}

      if (coapp === "COAPP") {
        if (!value.coname) {
          setError("Please enter co-applicant name");
        errorToggle()


          return;
        }
      }

      
    


      const headers = {
        'Authorization': `Bearer ${authToken && authToken.value}`,
        'Content-Type': 'application/json',
      };
      
      
      const data = {
        mobile: value.phone,
        firstName: value.fname,
        lastName: value.lname,
        instituteName:value.inst,
        // midName:value.midname,
        studentName:value.sname,
        dateOfBirth: value.dob,
        courseName: value.course,
        courseFees: value.coursefee,
        gender: value.gender,
        panId: value.pannum,
        aadhaarId:`XXXXXXXX${value.adhaarnum.substr(8,12)}`,
        email: value.Email1,
        currentAddress: value.Address,
        currentCity: value.City,
        currentState: value.State,
        currentPincode: value.Zip,
        panImage: value.pan,
        aadhaarFrontImage: value.adharf,
        aadhaarBackImage: value.adharb,
        isCoapplicant: coapp === "CO_APP",
        relatedTo: value.coname,
        employmentType:
          radio === "EMPLOYED"
            ? "salaried"
            : radio === "SELF_EMPLOYED"
            ? "self-employed"
            : "unemployed",
        employerName: value.emp,
        salary: value.sal,
        incomePerMonth: value.Income,
        typeOfBusiness: value.typofB,
        salesperson: value.salesperson,
        loanTenure: value.tenure,
        ocrId: ocrData.id,
      };

      if(authToken.user_status === "New"||authToken.user_status==="Edited"||authToken.user_status==="Coapplicant")
      {
        handleLocationClick()
        handleStartSession()
      }
      else if(authToken.user_status==="Admin")
      {

        axios.post(`${process.env.REACT_APP_BASE_URL}/summary/create`, data, { headers })
          .then((response) => {
            const userId = response.data.data.userId;
            setConsentId(userId)
            
            if (userId) {
              const data2 = { userId ,
              latitude : location.latitude,
                        longitude : location.longitude,};
        
              
              axios.post(`${process.env.REACT_APP_BASE_URL}/end-user/submit`, data2, { headers })
                .then(() => {
                  alert("Submitted, Consent message sent to customer");
                  navigate("/upload");
                })
                .catch((error) => console.log("error", error));
            }
          })
          .catch((error) => console.log("error", error));
        
      }
      
      
    };

    const [panImage,setPanImage]=useState('')
    const [aadhaarImageFront,setaadhaarImageFront]=useState('')
    const [aadhaarImageBack,setaadhaarImageBack]=useState('')
      function handleS3ImageLink(payload:string){
        console.log(payload)

        return new Promise((resolve) => {
        const result= axios.get(`${process.env.REACT_APP_BASE_URL}/file/url-content?path=${payload}`)
         
          resolve(result);      // this is you resolving the promise you return
        });
        // .then((res)=>{

        //   return res?.data?.data
        // }
        // )
        // .catch((err)=>{
        //   console.log(err)
        //   return err
        // })
    }

  

  useEffect(() => {
    const query = new URLSearchParams(search);
    const paramField = query.get("coapp");

    if (paramField) {
      setCoapp("CO_APP");
    }

    var myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${authToken && authToken.value}`);

    var requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    if (authToken.mob&&(authToken.user_status==="Edited"||authToken.user_status==="Coapplicant")) {
      setPhoneCheck(authToken.mob)
      setValue((val: FormValue) => ({
        ...val,
        phone: authToken.mob,
      }));
    }

    // fetch(`${process.env.REACT_APP_BASE_URL}/institute/info?name=med`, requestOptions);
    if(ocrData.id) {
      fetch(`${process.env.REACT_APP_BASE_URL}/ocr/data?id=${ocrData.id}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);

          if (result?.data) {
            setValue((val: FormValue) => ({
              ...val,
              fname: result?.data?.fullNamePerAadhaar?.split(" ")[0] || "",
              lname:
                result?.data?.fullNamePerAadhaar?.substr(
                  result?.data?.fullNamePerAadhaar?.indexOf(" ") + 1
                ) || "",
              dob: result?.data?.dobPerPan || "",
              gender: result?.data?.gender || "",
              Address: result?.data?.address || "",
              City: result?.data?.city || "",
              State: result?.data?.state || "",
              Zip: result?.data?.pincode || "",
              pannum: result?.data?.panId || "",
              adhaarnum: result?.data?.aadhaarId || "",
              adharf: result?.data?.aadhaarImage1 || "",
              adharb: result?.data?.aadhaarImage2 || "",
              pan: result?.data?.panImage || "",
            }));
          }
        })
        .catch((error) => console.log("error", error));
    }

    if(authToken.user_status==="Edited"||authToken.user_status==="Coapplicant")
    {
      axios.get(`${process.env.REACT_APP_BASE_URL}/summary`,{
          headers:{
            "Authorization":`Bearer ${sessionStorage.getItem('token')}`
          }
      }).then((res: any)=>{
        console.log(res,"USER DATA")
        handleS3ImageLink(res?.data?.data?.panImage).then((resolvedValue:any)=>setPanImage(resolvedValue.data.data))
        handleS3ImageLink(res?.data?.data?.aadhaarFrontImage).then((resolvedValue:any)=>setaadhaarImageFront(resolvedValue.data.data))
        handleS3ImageLink(res?.data?.data?.aadhaarBackImage).then((resolvedValue:any)=>setaadhaarImageBack(resolvedValue.data.data))
        handleLocationClick()
        setValue((val: FormValue) => ({
          ...val,
          fname: res?.data?.data?.firstName || "",
          lname: res?.data?.data?.lastName || "",
          dob: res?.data?.data?.dateOfBirth || "",
          gender: res?.data?.data?.gender || "",
          Address: res?.data?.data?.currentAddress || "",
          City: res?.data?.data?.currentCity || "",
          State: res?.data?.data?.currentState || "",
          Zip: res?.data?.data?.currentPincode || "",
          pannum: res?.data?.data?.panId || "",
          inst:res?.data?.data?.instituteName,
          course:res?.data?.data?.courseName,
          sal:res?.data?.data?.salary,
          emp:res?.data?.data?.employerName,
          empType:res?.data?.data?.employmentType,
          adhaarnum: res?.data?.data?.aadhaarId || "",
          adharf: res?.data?.data?.aadhaarFrontImage || "",
          adharb: res?.data?.data?.aadhaarBackImage || "",
          Email1: res?.data?.data?.email || "",
          tenure: res?.data?.data?.loanTenure || "",
          sname: res?.data?.data?.studentName || "",
          coname:res?.data?.data?.relatedTo || "",
          coursefee: res?.data?.data?.courseFees || "",
          pan: res?.data?.data?.panImage || "",

        }));
      })
    }
    
  }, [ocrData, authToken]);

  const uploadFile1 = (event: any) => {
    let fileList = event.target.files;
    if (fileList) {
      const formdoc = new FormData();
      formdoc.append("type", "adhar_front");
      formdoc.append("file", fileList[0]);

      var myHeaders = new Headers();

      myHeaders.append(
        "Authorization",
        `Bearer ${authToken && authToken.value}`
      );

      var requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: formdoc,
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/file/upload`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log(data?.data?.s3Url);
          setValue((val: FormValue) => ({
            ...val,
            adharf: data?.data?.s3Url,
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  const uploadFile2 = (event: any) => {
    let fileList = event.target.files;
    if (fileList) {
      const formdoc = new FormData();
      formdoc.append("type", "adhar_back");
      formdoc.append("file", fileList[0]);

      var myHeaders = new Headers();

      myHeaders.append(
        "Authorization",
        `Bearer ${authToken && authToken.value}`
      );

      var requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: formdoc,
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/file/upload`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log(data?.data?.s3Url);
          setValue((val: FormValue) => ({
            ...val,
            adharb: data?.data?.s3Url,
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  const uploadFile3 = (event: any) => {
    let fileList = event.target.files;
    if (fileList) {
      const formdoc = new FormData();
      formdoc.append("type", "pan");
      formdoc.append("file", fileList[0]);

      var myHeaders = new Headers();

      myHeaders.append(
        "Authorization",
        `Bearer ${authToken && authToken.value}`
      );

      var requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: formdoc,
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/file/upload`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log(data?.data?.s3Url);
          setValue((val: FormValue) => ({
            ...val,
            pan: data?.data?.s3Url,
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  const [link,setLink]=React.useState("");

  
  const handleStartSession=()=>{
    const randomGen= Date.now().toString(36) + Math.random().toString(36).substr(2);
   
    ( window as any).getBureauSession('708587bad0904485abe1127847dd62cd',randomGen,value.fname.trim(),'',value.lname.trim(),value.phone).then((res : any)=>{
      console.log(res)
    toggle()
    setLink(res)
    }
    )
  }

  const handleLoadSession=async()=>{
    const result=await ( window as any).startBureauSession()
    if(result)
    {
      switch(result.status)
      {
        case "SUCCESS": const headers = {
                        'Authorization': `Bearer ${authToken && authToken.value}`,
                        'Content-Type': 'application/json',
                      };
                      
                      
                      const data = {
                        mobile: value.phone,
                        firstName: value.fname.trim(),
                        lastName: value.lname.trim(),
                        instituteName:value.inst,
                        // midName:value.midname,
                        studentName:value.sname,
                        dateOfBirth: value.dob,
                        courseName: value.course,
                        courseFees: value.coursefee,
                        gender: value.gender,
                        panId: value.pannum.trim(),
                        aadhaarId:`XXXXXXXX${value.adhaarnum.substr(8,12)}`,
                        email: value.Email1,
                        currentAddress: value.Address,
                        currentCity: value.City,
                        currentState: value.State.trim(),
                        currentPincode: value.Zip,
                        panImage: value.pan,
                        aadhaarFrontImage: value.adharf,
                        aadhaarBackImage: value.adharb,
                        isCoapplicant: coapp === "CO_APP",
                        relatedTo: value.coname,
                        employmentType:
                          radio === "EMPLOYED"
                            ? "salaried"
                            : radio === "SELF_EMPLOYED"
                            ? "self-employed"
                            : "unemployed",
                        employerName: value.emp,
                        salary: value.sal,
                        incomePerMonth: value.Income,
                        typeOfBusiness: value.typofB,
                        salesperson: value.salesperson,
                        loanTenure: value.tenure,
                        ocrId: ocrData.id,
                      };
                      handleLocationClick()
                       axios.post(`${process.env.REACT_APP_BASE_URL}/summary/create`, data, { headers })
                      .then((response) => {

                        const userId = response.data.data;
                        
                        
                        if (userId) {
                          const data2 = { userId,
                          latitude : location.latitude,
                        longitude : location.longitude, };
                    
                          
                          axios.post(`${process.env.REACT_APP_BASE_URL}/end-user/submit`, data2, { headers })
                            .then(() => {
                              toggle()
                              navigate("/thank-you");
                            })
                            .catch((error) => console.log("error", error));
                        }
                      })
                      .catch((error) => console.log("error", error));
       
                      break;
        case "EXIT":
                    alert("Retry Submit");
                    toggle();
                    break;
        case "ERROR":
                    alert("Error Please Try Later");
                    toggle();
                    break;
        default:alert("Contact our team for assistance");
                break;
      }
    }
  }

  
  return (
    <div>
       <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Consent:
         <img src={close}style={{width:'15px',height:'15px',marginTop:'10px',cursor:'pointer',position:'absolute',right:'2rem'}} onClick={toggle}/></ModalHeader>
        <ModalBody>
        <iframe width="400" height="600" src={link} onLoad={handleLoadSession} title="bureau">
        </iframe>
        </ModalBody>
        {/* <ModalFooter>
          <button color="primary" onClick={()=>toggle()}>
            Do Something
          </button>{' '}
          <button color="secondary" onClick={()=>toggle()}>
            Cancel
          </button>
        </ModalFooter> */}
      
      </Modal>  
      <Modal style={{marginTop:'15rem'}} isOpen={errorModal} toggle={()=>errorToggle()}>
        <ModalHeader style={{color:'red',justifyContent:'center',fontStyle:'bold',backgroundColor:'#FEEDEE'}}>
             Error
         <img src={close}style={{width:'15px',height:'15px',marginTop:'10px',cursor:'pointer',position:'absolute',right:'2rem'}} onClick={errorToggle}/></ModalHeader>
        <ModalBody style={{display:'flex',justifyContent:'center',fontStyle:'bold',borderTop:'1px solid #d32028',}}>
       <p style={{fontWeight:'bold',fontSize:'20px'}}>{error}</p>
        </ModalBody>
    
      </Modal>  
      <div className={styles.main}>
        <Navbar />
        <div className={styles.container}>
          <div
            onChange={(event) =>
              setCoapp((event.target as HTMLInputElement).value)
            }
            defaultValue="APP"
            className={styles.radio}
          >
            <label>
              <input
                type="radio"
                value="APP"
                name="app_type"
                disabled={coapp === "CO_APP"}
                checked={coapp === "APP"}
              />
              Applicant
            </label>
            <label>
              <input
                type="radio"
                value="CO_APP"
                name="app_type"
                disabled={coapp === "APP"}

                checked={coapp === "CO_APP"}
              />
              Co-Applicant
            </label>
          </div>
          {/* {error.trim() && (
            <ErrorDialog error={error} onClose={() => setError(" ")} />
          )} */}
        </div>
        {coapp === "CO_APP" ? (
          <>
            <div className={styles.container}>
              <div className={styles.inputField}>
                <Label text="First Name" />
                <InputText
                  placeholder="First Name"
                  type="text"
                  changeHandler={(e:any) => handleChange("fname", e.target.value)}
                  value={value.fname}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Last Name" />
                <InputText
                  placeholder="Last Name"
                  type="text"
                  changeHandler={(e:any) => handleChange("lname", e.target.value)}
                  value={value.lname}
                />
              </div>
              {/* <div className={styles.inputField}>
                <Label text="Student Name" />
                <InputText
                  placeholder="Student Name"
                  type="text"
                  changeHandler={(e:any) => handleChange("sname", e.target.value)}
                  value={value.sname}
                />
              </div> */}
              <div className={styles.inputField}>
                <Label text="Date of Birth" />
                <InputText
                  placeholder="Date of Birth"
                  type="date"
                  changeHandler={(e:any) => handleChange("dob", e.target.value)}
                  value={value.dob}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Gender" />
                <InputText
                  placeholder="Gender"
                  type="option"
                  defaultValue={value.gender}
                  changeHandler={(e:any) => handleChange("gender", e.target.value)}
                  
                  options={[
                    {
                      placeholder: "Gender",
                      value: "",
                    },
                    {
                      placeholder: "Male",
                      value: "Male",
                    },
                    {
                      placeholder: "Female",
                      value: "Female",
                    },
                  ]}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Phone Number" />
                <InputText
                  placeholder="Phone Number"
                  disabled={authToken.user_status==="Edited"||authToken.user_status==="Coapplicant"?true:false}
                  type="tel"
                  changeHandler={(e:any) => handleChange("phone", e.target.value)}
                  value={value.phone}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Related Applicant" />
                <InputText
                  placeholder="Related Applicant"
                  type="text"
                  changeHandler={(e:any) => handleChange("coname", e.target.value)}
                  value={value.coname}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Email address" />
                <InputText
                  placeholder="Email address"
                  type="email"
                  changeHandler={(e:any) => handleChange("Email1", e.target.value)}
                  value={value.Email1}
                />
              </div>
              {/* <div className={styles.inputField}>
                <Label text="Sales Person Name" />
                <InputText
                  placeholder="Sales Person Name"
                  type="text"
                  changeHandler={(e:any) => handleChange("salesperson", e.target.value)}
                  value={value.salesperson}
                />
              </div> */}
              <div className={styles.inputField}>
                <Label text="Tenure" />
                <InputText
                  placeholder="Tenure"
                  type="number"
                  min={0}
                  changeHandler={(e:any) =>
                    handleChange("tenure", e.target.value)
                  }
                  value={value.tenure}
                />
              </div>
            </div>
            <div className={styles.container}>
              <div className={styles.inputField}>
                <Label text="Address" />
                <InputText
                  placeholder="Address"
                  type="text"
                  changeHandler={(e:any) => handleChange("Address", e.target.value)}
                  value={value.Address}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="City" />
                <InputText
                  placeholder="City"
                  type="text"
                  changeHandler={(e:any) => handleChange("City", e.target.value)}
                  value={value.City}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="State" />
                <InputText
                  placeholder="State"
                  type="option"
                  defaultValue={value.State}
                  options={states.map(state => ({ placeholder: state, value: state }))}
                  changeHandler={(e:any) => handleChange("State", e.target.value)}
                  value={value.State}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Zip" />
                <InputText
                  placeholder="Zip"
                  type="text"
                  changeHandler={(e:any) => handleChange("Zip", e.target.value)}
                  value={value.Zip}
                />
              </div>
            </div>
            <div className={styles.container}>
              <div
                onChange={(event) =>
                  setRadio((event.target as HTMLInputElement).value)
                }
                defaultValue="EMPLOYED"
                className={styles.radio}
              >
                <label>
                  <input
                    type="radio"
                    value="EMPLOYED"
                    name="employment"
                    checked={radio==="EMPLOYED"}
                  />
                  Salaried
                </label>
                <label>
                  <input
                    type="radio"
                    value="SELF_EMPLOYED"
                    name="employment"
                    checked={radio==="SELF_EMPLOYED"}
                  />
                  Self Employed
                </label>
                <label>
                  <input
                    type="radio"
                    value="UNEMPLOYED"
                    name="employment"
                    checked={radio==="UNEMPLOYED"}
                  />
                  Unemployed
                </label>
              </div>
              {radio === "EMPLOYED" && (
                <>
                  <div className={styles.inputField}>
                    <Label text="Employer Name" />
                    <InputText
                      placeholder="Employer Name"
                      type="text"
                      changeHandler={(e:any) => handleChange("emp", e.target.value)}
                      value={value.emp}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <Label text="Salary" />
                    <InputText
                      placeholder="Salary"
                      type="number"
                      changeHandler={(e:any) => handleChange("sal", e.target.value)}
                      value={value.sal}
                    />
                  </div>
                </>
              )}
              {radio === "SELF_EMPLOYED" && (
                <>
                  <div className={styles.inputField}>
                    <Label text="Type of Business" />
                    <InputText
                      placeholder="Type of Business"
                      type="text"
                      changeHandler={(e:any) =>
                        handleChange("typofB", e.target.value)
                      }
                      value={value.typofB}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <Label text="Income per Month" />
                    <InputText
                      placeholder="Income per Month"
                      type="number"
                      changeHandler={(e:any) =>
                        handleChange("Income", e.target.value)
                      }
                      value={value.Income}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={styles.container}>
              <div className={styles.inputField}></div>
              <div className={styles.inputField}>
                <Label text="Aadhaar Front" />
                <div className={styles.inputField}>
                  <input
                    type="file"
                    name="aadharFront"
                    onChange={(e: any) => {
                      uploadFile1(e);
                    }}
                  />
                   <img
                    src={value.adharf || ""}
                    alt=""
                    style={{
                      height: 300,
                      width:value.adharf?"100%":""
                    }}
                  />
                </div>
                <Label text="Aadhaar Back" />
                <div className={styles.inputField}>
                  <input
                    type="file"
                    name="aadharBack"
                    onChange={(e: any) => {
                      uploadFile2(e);
                    }}
                  />
                   <img
                    src={value.adharb || ""}
                    alt=""
                    style={{
                      height: 300,
                      width:value.adharb?"100%":""
                    }}
                  />
                </div>
                <Label text="Aadhaar Number" />
                <InputText
                  placeholder="Aadhaar Number"
                  type="text"
                  changeHandler={(e:any) =>
                    handleChange("adhaarnum", e.target.value)
                  }
                  value={value.adhaarnum}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Pan Card" />
                <div className={styles.inputField}>
                  <input
                    type="file"
                    name="panCard"
                    onChange={(e: any) => {
                      uploadFile3(e);
                    }}
                  />
                  <img
                    src={value.pan || ""}
                    alt=""
                    style={{
                      height: 300,
                      width:value.pan?"100%":""
                    }}
                  />
                </div>
                <Label text="Pan Number" />
                <InputText
                  placeholder="Pan Number"
                  type="text"
                  changeHandler={(e:any) => handleChange("pannum", e.target.value)}
                  value={value.pannum}
                />
              </div>
              {/* <div className={styles.inputField}></div>
              <div className={styles.inputField}>
                <Label text="Aadhaar Number" />
                <InputText
                  placeholder="Aadhaar Number"
                  type="text"
                  changeHandler={(e:any) =>
                    handleChange("adhaarnum", e.target.value)
                  }
                  value={value.adhaarnum}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Aadhaar Card" />
                <div>
                  <img
                    src={value.adharf || ""}
                    alt=""
                    style={{
                      height: 300,
                    }}
                  />
                  <img
                    src={value.adharb || ""}
                    alt=""
                    style={{
                      height: 300,
                    }}
                  />
                </div>
              </div>
              <div className={styles.inputField}>
                <Label text="Pan Number" />
                <InputText
                  placeholder="Pan Number"
                  type="text"
                  changeHandler={(e:any) => handleChange("pannum", e.target.value)}
                  value={value.pannum}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Pan Card" />
                <div>
                  <img
                    src={value.pan || ""}
                    alt=""
                    style={{
                      height: 300,
                    }}
                  />
                </div>
              </div> */}
            </div>
            {/* <div className={styles.container}>
          <TypeAheadDropDown
            iteams={[
              "Agra",
              "Allahabad",
              "Aligarh",
            ]}
          />
        </div> */}
            <div className={styles.container}>
    
            {/* <Button onPress={handleSubmit(value)} text="Submit" /> */}
               <button  className={styles.buttonForm} type="button" onClick={()=>handleSubmit(value)} >Submit</button>

           
        
     
            </div>
          </>
        ) : (
          <>
            <div className={styles.container}>
              <div className={styles.inputField}>
                <Label text="Institute Name" />
                <div className={styles.inputField}>
                <input
                  className={styles.instituteSearch}
                  id="styleinstituteNameField"
                  placeholder="Institute Name"
                  type="text"
                  onChange={(e:any)=>getInstituteInfo(e.target.value)}
                  defaultValue={selectedInstitute?selectedInstitute:value.inst}
                  

                  
                />
                 <div style={{marginTop:'0.5em',position: 'absolute',zIndex:'999',backgroundColor:'#fff',boxShadow:'0px 0px 3px 0px rgb(0,0,0.4,0.7)',height:instituteList.length>0?'25%':'0px',overflowY: 'scroll',width:'58%',color:"black",marginLeft:'0rem'}}>
                  {
                     instituteList.map((item : any)=>{
                      return(
                        <div onClick={()=>{handleSearchInstituteDetails(item.brandName)}} style={{cursor:'pointer',border:'1px solid #DbDbDB',}}>
                        <p  style={{fontWeight:'600',paddingTop:'0.5em',}}>{item.brandName}</p>
                        </div>
                      )
                      }
                      )

                  }
                  </div>
              </div>
                
              </div>
             
              <div className={styles.inputField}>
                <Label text="Course Name" />
                <InputText
                  placeholder="Course Name"
                  type="text"
                  changeHandler={(e:any) => handleChange("course", e.target.value)}
                  value={value.course}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Course Fee" />
                <InputText
                  min={0}
                  placeholder="Course Fee"
                  type="number"
                  changeHandler={(e:any) =>
                    handleChange("coursefee", e.target.value)
                  }
                  value={value.coursefee}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="First Name" />
                <InputText
                  placeholder="First Name"
                  type="text"
                  changeHandler={(e:any) => handleChange("fname", e.target.value)}
                  value={value.fname}
                />
              </div>
              {/* <div className={styles.inputField}>
                <Label text="Middle Name" />
                <InputText
                  placeholder="Middle Name"
                  type="text"
                  changeHandler={(e:any) => handleChange("midname", e.target.value)}
                  value={value.fname}
                />
              </div> */}
              <div className={styles.inputField}>
                <Label text="Last Name" />
                <InputText
                  placeholder="Last Name"
                  type="text"
                  changeHandler={(e:any) => handleChange("lname", e.target.value)}
                  value={value.lname}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Student Name" />
                <InputText
                  placeholder="Student Name"
                  type="text"
                  changeHandler={(e:any) => handleChange("sname", e.target.value)}
                  value={value.sname}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Date of Birth" />
                <InputText
                  placeholder="Date of Birth"
                  type="date"
                  changeHandler={(e:any) => handleChange("dob", e.target.value)}
                  value={value.dob}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Gender" />
                <InputText
                  placeholder="Gender"
                  type="option"
                  defaultValue={value.gender}
                  changeHandler={(e:any) => handleChange("gender", e.target.value)}
                  value={value.gender}
                  options={[
                    {
                      placeholder: "Gender",
                      value: "",
                    },
                    {
                      placeholder: "Male",
                      value: "male",
                    },
                    {
                      placeholder: "Female",
                      value: "female",
                    },
                  ]}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Phone Number" />
                <InputText
                  placeholder="Phone Number"
                  type="tel"
                  disabled={authToken.user_status==="Edited"||authToken.user_status==="Coapplicant"?true:false}
                  changeHandler={(e:any) => handleChange("phone", e.target.value)}
                  value={value.phone}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Email address" />
                <InputText
                  placeholder="Email address"
                  type="email"
                  changeHandler={(e:any) => handleChange("Email1", e.target.value)}
                  value={value.Email1}
                />
              </div>
             {authToken.user_status==="Admin"? <div className={styles.inputField}>
                <Label text="Sales Person Name" />
                <InputText
                  placeholder="Sales Person Name"
                  type="text"
                  changeHandler={(e:any) => handleChange("salesperson", e.target.value)}
                  value={value.salesperson}
                />
              </div>:<></>}
              <div className={styles.inputField}>
                <Label text="Tenure" />
                <InputText
                min={0}
                  placeholder="Tenure"
                  type="number"
                  changeHandler={(e:any) =>
                    handleChange("tenure", e.target.value)
                  }
                  value={value.tenure}
                />
              </div>
            </div>
            <div className={styles.container}>
              <div className={styles.inputField}>
                <Label text="Address" />
                <InputText
                  placeholder="Address"
                  type="text"
                  changeHandler={(e:any) => handleChange("Address", e.target.value)}
                  value={value.Address}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="City" />
                <InputText
                  placeholder="City"
                  type="text"
                  changeHandler={(e:any) => handleChange("City", e.target.value)}
                  value={value.City}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="State" />
                <InputText
                  placeholder="State"
                  type="option"
                  defaultValue={value.State}
                  options={states.map(state => ({ placeholder: state, value: state }))}
                  changeHandler={(e:any) => handleChange("State", e.target.value)}
                  value={value.State}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Zip" />
                <InputText
                  placeholder="Zip"
                  type="text"
                  changeHandler={(e:any) => handleChange("Zip", e.target.value)}
                  value={value.Zip}
                />
              </div>
            </div>
            <div className={styles.container}>
              <div
                onChange={(event) =>
                  setRadio((event.target as HTMLInputElement).value)
                }
                defaultValue="EMPLOYED"
                className={styles.radio}
              >
                <label>
                  <input
                    type="radio"
                    value="EMPLOYED"
                    name="employment"
                    checked={radio === "EMPLOYED"}
                  />
                  Salaried
                </label>
                <label>
                  <input
                    type="radio"
                    value="SELF_EMPLOYED"
                    name="employment"
                    checked={radio === "SELF_EMPLOYED"}
                  />
                  Self Employed
                </label>
                <label>
                  <input
                    type="radio"
                    value="UNEMPLOYED"
                    name="employment"
                    checked={radio === "UNEMPLOYED"}
                  />
                  Unemployed
                </label>
              </div>
              {radio === "EMPLOYED" && (
                <>
                  <div className={styles.inputField}>
                    <Label text="Employer Name" />
                    <InputText
                      placeholder="Employer Name"
                      type="text"
                      changeHandler={(e:any) => handleChange("emp", e.target.value)}
                      value={value.emp}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <Label text="Salary" />
                    <InputText
                      placeholder="Salary"
                      type="number"
                      changeHandler={(e:any) => handleChange("sal", e.target.value)}
                      value={value.sal}
                    />
                  </div>
                </>
              )}
              {radio === "SELF_EMPLOYED" && (
                <>
                  <div className={styles.inputField}>
                    <Label text="Type of Business" />
                    <InputText
                      placeholder="Type of Business"
                      type="text"
                      changeHandler={(e:any) =>
                        handleChange("typofB", e.target.value)
                      }
                      value={value.typofB}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <Label text="Income per Month" />
                    <InputText
                      placeholder="Income per Month"
                      type="number"
                      changeHandler={(e:any) =>
                        handleChange("Income", e.target.value)
                      }
                      value={value.Income}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={styles.container}>
              <div className={styles.inputField}></div>
              <div className={styles.inputField}>
                <Label text="Aadhaar Front" />
                <div className={styles.inputField}>
                  <input
                    type="file"
                    name="aadharFront"
                    onChange={(e: any) => {
                      uploadFile1(e);
                    }}
                  />
                  <img
                    src={`data:image/jpeg;base64,${aadhaarImageFront}`}
                    alt=""
                    style={{
                      height: 300,
                      width:value.adharf?"100%":""
                    }}
                  />
                </div>
                <Label text="Aadhaar Back" />
                <div className={styles.inputField}>
                  <input
                    type="file"
                    name="aadharBack"
                    onChange={(e: any) => {
                      uploadFile2(e);
                    }}
                  />
                  <img
                    src={`data:image/jpeg;base64,${aadhaarImageBack}`}
                    
                    alt=""
                    style={{
                      height: 300,
                       width:value.adharb?"100%":""
                    }}
                  />
                </div>
                <Label text="Aadhaar Number" />
                <InputText
                  placeholder="Aadhaar Number"
                  type="text"
                  changeHandler={(e:any) =>
                    handleChange("adhaarnum", e.target.value)
                  }
                  value={value.adhaarnum}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Pan Card" />
                <div className={styles.inputField}>
                  <input
                    type="file"
                    name="panCard"
                    onChange={(e: any) => {
                      uploadFile3(e);
                    }}
                  />
                  <img
                    src={`data:image/jpeg;base64,${panImage}`}
                    alt=""
                    style={{
                      height: 300,
                       width:value.pan?"100%":""
                    }}
                  />
                </div>
                <Label text="Pan Number" />
                <InputText
                  placeholder="Pan Number"
                  type="text"
                  changeHandler={(e:any) => handleChange("pannum", e.target.value)}
                  value={value.pannum}
                />
              </div>
              {/* <div className={styles.inputField}></div>
              <div className={styles.inputField}>
                <Label text="Aadhaar Number" />
                <InputText
                  placeholder="Aadhaar Number"
                  type="text"
                  changeHandler={(e:any) =>
                    handleChange("adhaarnum", e.target.value)
                  }
                  value={value.adhaarnum}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Aadhaar Card" />
                <div>
                  <img
                    src={value.adharf || ""}
                    alt=""
                    style={{
                      height: 300,
                    }}
                  />
                  <img
                    src={value.adharb || ""}
                    alt=""
                    style={{
                      height: 300,
                    }}
                  />
                </div>
              </div>
              <div className={styles.inputField}>
                <Label text="Pan Number" />
                <InputText
                  placeholder="Pan Number"
                  type="text"
                  changeHandler={(e:any) => handleChange("pannum", e.target.value)}
                  value={value.pannum}
                />
              </div>
              <div className={styles.inputField}>
                <Label text="Pan Card" />
                <div>
                  <img
                    src={value.pan || ""}
                    alt=""
                    style={{
                      height: 300,
                    }}
                  />
                </div>
              </div> */}
            </div>
            {/* <div className={styles.container}>
          <TypeAheadDropDown
            iteams={[
              "Agra",
              "Allahabad",
              "Aligarh",
            ]}
          />
        </div> */}
            
            <div className={styles.container}>
             
              
               <button  className={styles.buttonForm} type="button" onClick={()=>handleSubmit(value)} >Submit</button>
      
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AdminForm;
